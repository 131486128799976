<template>
  <div data-cy="benchmarks">
    <PageHeaderDisplay
      title="Targets"
      sub-title="An insight into how you're portfolios and assets are performing against various benchmarks."
      category="Targets"
      category-logo="fa-bullseye"
      :category-route="{ name: $route.name }"
    />
    <TabView route-name="targets" :tabs="tabs" :exact="false">
      <router-view />
    </TabView>
  </div>
</template>
<script>
import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import TabView from '@/components/TabView';

export default {
  name: 'TargetView',
  components: {
    PageHeaderDisplay,
    TabView
  },
  computed: {
    tabs() {
      return [
        {
          name: 'Dashboard',
          route: 'targets-dashboard',
          icon: 'fa-list'
        },
        {
          name: 'Manage',
          route: 'targets-manage',
          icon: 'fa-wrench'
        }
      ];
    }
  }
};
</script>
